import { useInView } from 'react-intersection-observer';

export default function Video({ className, large, w_1080, w_768, w_480 }) {
  const [ref, inView] = useInView({
    triggerOnce: true, // Load content only once when it comes into view
  });
  return (
    <div ref={ref} className="video-container">
      <video
        preload="none"
        autoPlay
        muted
        loop
        playsInline
        className={`${className}`}
      >
        {inView && (
          <source src={w_480} type="video/mp4" media="(max-width: 480px)" />
        )}
        {inView && (
          <source src={w_768} type="video/mp4" media="(max-width: 768px)" />
        )}
        {inView && (
          <source src={w_1080} type="video/mp4" media="(max-width: 1080px)" />
        )}
        {inView && (
          <source src={large} type="video/mp4" media="(min-width: 1081px)" />
        )}
      </video>
    </div>
  );
}
